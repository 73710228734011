
<template>
    <div class="dialog">
        <el-dialog destroy-on-close	 style="z-index: 100000;" center :visible.sync="dialogVisible" width="60%">
            <div slot="title">
                <h4>{{ title }}</h4>
            </div>
            <div class="flex flex-h-center">
                <video ref="video" style="width: 100%;" controls :src="src"></video>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="close()" round style="width:100px">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "videoDialog",
    data() {
        return {
            dialogVisible: false,
            title: "分享",
            src: "",
        };
    },
    props: {
    },
    methods: {
        show(title, src) {
            this.title = title;
            this.src = src;
            this.dialogVisible = true;
        },

        close() {
            this.$refs.video
            this.dialogVisible = false
        }

    },
};
</script>
<style scoped>


.dialog>>>.el-dialog__wrapper {
    z-index: 10000 !important;
}
</style>
