<template>
    <VueDragResize v-if="isShow" class="fix-center" style="position:fixed" :z="2000"
        :minw="420" :minh="360" :w="width" :h="height" v-on:resizing="resize">
        <el-card style="cursor:move;" :style="{ height: height + 'px', width: width + 'px' }">
            <h4 slot="header">{{$t('读法标记')}}</h4>
            <div class="center">
                <el-image :style="{ height: (height - 200) + 'px' }" :src="imageUrl()" fit="scale-down"></el-image>
            </div>
            <div class="line"></div>
            <div class="st margin-t ellipsis">
                /{{$t('为断句，红色为重读，绿色拉线字母为省读，下划线为连读')}}
            </div>
            <div class="right margin-t">
                <el-button round type="primary" @click="isShow = false">{{$t('关闭')}}</el-button>
            </div>
        </el-card>
    </VueDragResize>
</template>

<script>
import moment from 'moment';
import VueDragResize from 'vue-drag-resize'
export default {
    components: {
        VueDragResize
    },
    name: "imgResizePanel",
    data() {
        return {
            width: 600,
            height: 430,
            isShow: false,
            left: 0,
        };
    },
    props: {

        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    created() {

    },

    methods: {
        show() {
            this.height = 430;
            this.width = 600;
            this.isShow = true;
        },

        resize(res) {
            this.width = res.width;
            this.height = res.height;
        },
        imageUrl() {
            return this.$host + '/upload/图片/RA读法标记/' + this.source.no + '.png?v=' + (moment().format('MMDDHHmm'))
        },

    },
};
</script>
<style scoped>
.fix-center {
    margin-top: calc(50vh - 215px);
    margin-left: calc(50vw - 300px);
}
</style>