<template>
    <i @click.stop="" :class="value ? 'el-icon-star-on' : 'el-icon-star-off'"
        class="icon margin-l flex flex-h-center flex-v-center out"
        :style="{ color: value ? ActiveColor : inactiveColor, fontSize: value ? '30px' : '22px' }"><span class="in"
            v-if="value" :style="{color:color}">{{ value }}</span></i>
</template>

<script>
export default {
    name: "value",
    props: {
        value: {
            type: Number,
            default: 0
        },
        ActiveColor: {
            type: String,
            default: "#e6a13c"
        },
        inactiveColor: {
            type: String,
            default: "#999"
        },
        color: {
            type: String,
            default: "#fff"
        }
    },
    data() {
        return {
        };
    },
    methods: {

    },
};
</script>
<style scoped>
.out {
    position: relative;
    width: 30px;
}

.in {
    font-size: 12px;
    position: absolute;
}
</style>