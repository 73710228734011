<template>
    <div class="record-list">
        <el-empty v-if="list.length == 0" :description="`${$t('您还没有练习记录')}！`"></el-empty>
        <div v-for="(item, index) in list" :key="'item0_' + index">
            <div class="flex">
                <el-image class="avatar margin-x"
                    :src="item.user.avatar.indexOf('http') == 0 ? item.user.avatar : $host + item.user.avatar">
                </el-image>

                <div class="flex1">
                    <div class="flex flex-v-center flex-between">
                        <div class="flex flex-v-center" style="margin-bottom:5px;">
                            <el-tag v-if="item.user.role == 4" size="mini" style="margin-right:10px;" effect="dark">{{
                                $t('课程VIP') }} (PTE+CCL)</el-tag>
                            <el-tag v-else-if="item.user.role == 3" size="mini" style="margin-right:10px;"
                                effect="dark">{{
                                    $t('课程VIP') }} (CCL)</el-tag>
                            <el-tag v-else-if="item.user.role == 2" size="mini" style="margin-right:10px;"
                                effect="dark">{{
                                    $t('课程VIP') }} (PTE)</el-tag>
                            <el-tag v-if="item.user.role == 1" size="mini" style="margin-right:10px;"
                                effect="dark">VIP</el-tag>
                            <h4 class="st1">{{ item.user.nickName }}</h4>
                            <div class="st2 margin-l">
                                {{
                                    item.record_time
                                }}</div>
                        </div>
                    </div>

                    <!--one-->
                    <div>
                        <div class="mt">
                            <div class="flex flex-v-center flex-wrap">
                                <div class="flex flex-v-center" v-for="(word, index) in item.content" :key="index">
                                    <span
                                        :class="{ 'wrong0': word.split('/')[2] == 0, 'right': word.split('/')[2] == 1 }">{{
                                            word.split('/')[0]
                                        }}</span>
                                    <span :class="{ 'wrong': word.split('/')[2] == 0 }">
                                        {{ word.split('/')[2] == 0 ? '(' + word.split('/')[1] + ')' : ''
                                        }}
                                    </span>
                                    <span class="margin-rs" v-if="index < item.content.length - 1">,</span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-v-center margin-ts">
                            <div class="st">{{ item.score }}/{{ item.content.length }}{{$t('分')}}</div>
                            <div class="flex flex-v-center">
                                <!-- <el-tooltip class="st pointer icon-l margin-l" content="分享答案" placement="top">
                                    <i @click="share(item)" class="el-icon-share"></i>
                                </el-tooltip> -->

                                <div class="flex flex-v-center">
                                    <el-image @click="vote(item)"
                                        :src="'/web/image/zan' + (item.vote_id ? '_checked' : '') + '.png'"
                                        style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                    <span class="st">{{ item.votes_count }}</span>
                                </div>

                                <el-tooltip v-if="item.user.id == $store.state.user.id" class="st pointer icon-l margin-l"
                                    :content="$t('删除')" placement="top">
                                    <i @click="removeRecord(item.id, index)" class="el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- one end-->
                    <div>
                        <div v-if="item.items && item.collapse">
                            <el-divider></el-divider>
                            <div v-for="(item1, index1) in item.items" :key="'item1_' + index1">
                                <div class="mt">
                                    <div class="flex flex-v-center flex-wrap">
                                        <div class="flex flex-v-center" v-for="(word, index) in item1.content" :key="index">
                                            <span
                                                :class="{ 'wrong0': word.split('/')[2] == 0, 'right': word.split('/')[2] == 1 }">{{
                                                    word.split('/')[0]
                                                }}</span>
                                            <span :class="{ 'wrong': word.split('/')[2] == 0 }">
                                                {{ word.split('/')[2] == 0 ? '(' + word.split('/')[1] +
                                                    ')' : '' }}
                                            </span>
                                            <span class="margin-rs" v-if="index < item1.content.length - 1">,</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-v-center margin-ts">



                                    <div class="flex flex-v-center">

                                        <div class="st">{{ item.score }}/{{ item.content.length }}分</div>

                                        <div class="st2 margin-x">
                                            {{
                                                item1.record_time
                                            }}</div>
                                        <!-- <el-tooltip class="st pointer icon-l margin-l" content="分享答案" placement="top">
                                            <i @click="share(item1)" class="el-icon-share"></i>
                                        </el-tooltip> -->

                                        <div class="flex flex-v-center">
                                            <el-image @click="vote(item1)"
                                                :src="'/web/image/zan' + (item1.vote_id ? '_checked' : '') + '.png'"
                                                style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                            <span class="st">{{ item1.votes_count }}</span>
                                        </div>

                                        <el-tooltip v-if="item.user_id == $store.state.user.id"
                                            class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                            <i @click="removeRecord(item1.id, index, index1)" class="el-icon-delete"></i>
                                        </el-tooltip>
                                    </div>

                                </div>
                                <el-divider></el-divider>
                            </div>
                        </div>
                    </div>

                    <div v-if="item.items && item.items.length > 0" class="st1">{{$t('已')}}{{
                        item.collapse ? $t("展开") : $t("折叠")
                    }}{{
    item.items.length
}}{{$t('条记录')}}，<el-link type="primary" class="st" @click="item.collapse = !item.collapse">{{
    item.collapse ? $t("收起") : $t("点击查看")
}}</el-link></div>
                </div>
            </div>
            <el-divider></el-divider>
        </div>
        <!-- <share-dialog ref="shareDialog"></share-dialog> -->
    </div>
</template>

<script>

// import shareDialog from './ShareDialog.vue'
export default {
    components: {
        // shareDialog,
    },
    name: "sstRecords",
    data() {
        return {
            loadingDialogVisible: false,
            loading: null,
            total: 0,
        };
    },


    props: {
        list: {
            type: [],
            default: () => {
                return []
            },
        },
        tag: {
            type: String,
            default: "",
        },

        type: {
            type: String,
            default: "pte",
        },
    },
    created() {
    },
    methods: {


        vote(item) {
            let data = {};
            if (item.vote_id) {
                data["id"] = item.vote_id;
            } else {
                data["record_id"] = item.id;
            }
            this.$http.post("/api/source/work/vote", data).then((res) => {
                item.vote_id = res.id;
                if (res.id) {
                    item.votes_count++;
                } else {
                    item.votes_count--;
                }

            })
        },

        share(item) {
            let href = this.$domain + "/record?id=" + item.id
            this.$refs.shareDialog.show("分享练习", href, "");
        },


        removeRecord(id, index, index1) {
            this.$confirm('', this.$t('确认删除此记录') + '?', {
                center: true,
                confirmButtonText: this.$t('确定'),
                cancelButtonText: this.$t('取消'),
                type: 'primary'
            }).then(() => {
                this.$http.post("/api/source/work/record/remove", { id: id }).then((flag) => {
                    if (flag) {
                        this.$message({
                            type: 'success',
                            message: this.$t('删除成功') + '!'
                        });
                        if (index1) {
                            this.list[index].items.splice(index1, 1);
                        } else {
                            if (this.list[index].items.length > 0) {
                                let newItem = this.list[index].items[0];
                                this.list[index].items.splice(0, 1);
                                newItem.user = this.list[index].user;
                                newItem.items = this.list[index].items;
                                this.list.splice(index, 1, newItem);
                                console.log(this.list)
                            } else {
                                this.list.splice(index, 1);
                            }
                        }

                    }
                })
            }).catch(() => {

            });
        }
    },
};
</script>
<style scoped>
.wrong {
    letter-spacing: 1px;
    color: red;
}

.right {
    letter-spacing: 1px;
    color: green;
}

.wrong0 {
    letter-spacing: 1px;
    color: red;
    text-decoration: line-through red;

}

/* .wrong1 {
    letter-spacing: 1px;
    text-decoration: line-through red;
} */

.wrong1 {
    color: red;
    font-weight: 500;
}

.wrong2 {
    font-weight: 500;
    text-decoration: line-through;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.record-list>>>.el-divider--horizontal {
    margin: 10px 0 !important;
}
</style>
